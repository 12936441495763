import Layout from "../components/layout"
import {graphql} from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import Content, { HTMLContent } from '../components/Content'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'


export const ArtworkTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  coverquote,
  image,
  date,
  timeToRead,
  helmet,
  pageContext,
}) => {
  const ArtworkContent = contentComponent || Content;
  return (
    <Layout>
    <PageTransition>
    <BodyClassName className="artwork">

    <article className="article">
      <div className="content">
        <Helmet title={title+` • John Dilworth Art & Design`} />
        <ArtworkContent content={content} />
          {tags && tags.length ? (
            <div style={{ marginTop: `4rem` }}>
              <ul className="taglist">
                {tags.map(tag => (
                  <li key={tag + `tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
      </div>
    </article>
    </BodyClassName>
    </PageTransition>
    </Layout>
  )
}


ArtworkTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
}

const Artwork = ({ data }) => {
  const { markdownRemark: artwork } = data

  return (
    <ArtworkTemplate
      content={artwork.html}
      contentComponent={HTMLContent}
      description={artwork.frontmatter.description}
      tags={artwork.frontmatter.tags}
      title={artwork.frontmatter.title}
      date={artwork.frontmatter.date}
      coverquote={artwork.frontmatter.coverquote}
      image={artwork.frontmatter.image}
      timeToRead={artwork.timeToRead}
    />
  )
}

Artwork.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Artwork

export const pageQuery = graphql`
  query ArtworkByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        coverquote
        path
      }
    }
  }
`
